import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/layouts/Default/index.js";
import { Stack, SectionContainer } from '../components';
import { FaPaperPlane } from 'react-icons/fa';
import { Button } from '@cheapreats/react-ui';
import { navigate } from 'gatsby';
export const metadata = {
  title: 'Mentorship'
};
export const _frontmatter = {};
const layoutProps = {
  metadata,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <div>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1000px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/6ae4b0a162596b063ccdf8fd389830cc/29114/blank-img.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "40.8%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAEklEQVQY02N4SwFgGNU8VDQDAKYxvHBOQxVjAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "blank img",
              "title": "blank img",
              "src": "/static/6ae4b0a162596b063ccdf8fd389830cc/00d43/blank-img.png",
              "srcSet": ["/static/6ae4b0a162596b063ccdf8fd389830cc/63868/blank-img.png 250w", "/static/6ae4b0a162596b063ccdf8fd389830cc/0b533/blank-img.png 500w", "/static/6ae4b0a162596b063ccdf8fd389830cc/00d43/blank-img.png 1000w", "/static/6ae4b0a162596b063ccdf8fd389830cc/aa440/blank-img.png 1500w", "/static/6ae4b0a162596b063ccdf8fd389830cc/29114/blank-img.png 1920w"],
              "sizes": "(max-width: 1000px) 100vw, 1000px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </div>
    <h1>{`Join our Mentorship Program!`}</h1>
    <p>{`Our program traditionally takes on one student at a time for a minimum of 2 months and a maximum of 8 months. Currently, the role is unpaid,  however, this can change based on your performance throughout the program and financial  outlook of the company.`}</p>
    <p>{`Due to the current circumstances, we feel the responsibility  to train as many students as we can by pairing them with senior mentors ensuring that they are continuously learning and apply knowledge to real-world environments.`}</p>
    <p>{`Our mentorship program includes personalized learning plans tailored to your specific  goals, providing a more one-on-one learning and productive learning experience.`}</p>
    <Button onClick={() => navigate('')} margin='20px auto' primary padding='20px 30px' icon={FaPaperPlane} mdxType="Button">
    Apply now!
    </Button>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      